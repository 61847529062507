<template>
  <nav class="navbar navbar-expand-lg navbar-light pb-md-4 pb-3 fixed-top" style="background: #F6F4F0;">
    <div class="container-fluid">
      <router-link to="/" class="navbar-brand md:ml-0 ml-40 mr-30" style="width: 180px;">
        <img src="images/AEROMILES.ME (1).png" alt="logo icon">
      </router-link>
      <!-- <a class="navbar-brand" href="#">Navbar</a> -->
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent" style="margin-top: 17px;">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link to="/" class="nav-link px-md-3" :class="$route.path == '/' ? 'active' : ''" aria-current="page">Home</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/all-enquiries" class="nav-link px-md-3" :class="$route.path == '/all-enquiries' ? 'active' : ''">Travel Quest</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/blogs" class="nav-link px-md-3" :class="$route.path == '/blogs' ? 'active' : ''">Blogs</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/about" class="nav-link px-md-3" :class="$route.path == '/about' ? 'active' : ''">About us</router-link>
          </li>
          <li class="nav-item">
            <router-link to="/contact" class="nav-link px-md-3" :class="$route.path == '/contact' ? 'active' : ''">Contact</router-link>
          </li>
        </ul>
        <form class="d-flex">
          <div v-if="!User" class="d-md-flex items-center mr-20">
            <router-link to="login-user" class="button p-3 fw-400 text-14 border-dark-4 -blue-1 h-50 text-dark-4 new-green ml-20">Sign In / Register</router-link>
          </div>
          <div v-if="User">
              <div class="dropdown">
                <button class="dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                  <img v-if="User?.avatar != null" :src="User?.avatar"  class="small-userpic" alt="">
                  <img v-if="User?.avatar == null" src="images/user-demo.png" class="small-userpic" alt="">
                </button>
                <div class="dropdown-menu">
                  <router-link class="dropdown-item px-3 py-sm-3 py-2" to="/profile"><i class="fa-solid fa-user"></i> My Profile</router-link>
                  <router-link class="dropdown-item px-3 py-sm-3 py-2" to="/my-enquiries"><i class="fa-solid fa-plane"></i> My Travel Quest</router-link>
                  <router-link class="dropdown-item px-3 py-sm-3 py-2" to="/my-response"><i class="fa-brands fa-resolving"></i> My Responses</router-link>
                  <router-link class="dropdown-item px-3 py-sm-3 py-2" to="/my-transactions"><i class="fa-solid fa-money-bill-transfer"></i> My Transactions</router-link>
                  <router-link class="dropdown-item px-3 py-sm-3 py-2" to="#" @click="logout()">
                    <span><i class="fa-solid fa-arrow-right-from-bracket"></i> Logout</span>
                    <loader v-if="loader" />
                  </router-link>
                </div>
              </div>
          </div>
        </form>
      </div>
    </div>
  </nav>
</template>
  
  <script>
  import loader from '../loader.vue';

  export default {
    name: 'HeaderPage',
    components: {
      loader
    },

    data(){
        return{
          loader: false,
        }
    },

    computed:{
      User(){
        return this.$store.state.auth.user;
      }
    },

    methods: {
      logout(){
        this.loader = true;
        this.$store.dispatch('logout',{
          userToken: localStorage.getItem('token'),
          name: 'user',
        })
        .then((res)=>{
            console.log("res", res);
            this.loader = false;
        })
        .catch((err)=>{
            console.log("err", err);
            this.loader = false;
        })
      }
    },
  }
  </script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
  <style scoped>
  .header{
    background-color: unset;
  }
  .small-userpic{
    width: 50px;
    height: 50px;
    border-radius: 100%;
  }
  .navbar .navbar-nav .nav-item .nav-link{
    font-family: 'poppins', sans-serif;
    color: black;
  }
  .active{
    color: #0C6980 !important;
    font-weight: 600 !important;
  }
  .fa-solid, .fas, .fa-brands {
    width: 21px;
  }
  .dropdown-menu{
    left: -90px;
    border: none;
    min-width: 10.8rem;
  }
  @media screen and (max-width: 1000px) {
    .dropdown-menu{
      left: 0px;
    }
  }
  </style>
  