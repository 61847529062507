// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from './apis/user';
// import router from "../router/routes";

const state = {
    // Additional state if needed
    allResponses: '',
}

const mutations = {
    SET_ALL_RESPONSE_DATA(state, data) {
      state.allResponses = data
  },
}

const actions = {
    // Additional actions if needed
      getAllResponsedData({ commit }) {
            // return
            // return new Promise((resolve, reject) => {
                axios
                    .get(axios.defaults.baseURL + apis.GET_ALL_RESPONSE, {
                        headers: {
                          Authorization: "Bearer " + localStorage.getItem("token"),
                        },
                      })
                    .then((res) => {
                        console.log('getAllResponsedData---', res)
                        commit('SET_ALL_RESPONSE_DATA', res.data)
                    })
                    .catch((err) => {
                        console.log('getAllResponsedData err', err)
                    })
            // })
    },

}

const getters = {
    // Additional getters if needed
}

export default {
    state,
    mutations,
    actions,
    getters,
  };