<template>
  <div class="deznav">
    <div class="deznav-scroll">
      <ul class="metismenu" id="menu">
        <li :class="{ 'mm-active': $route.path === '/admin' }">
          <router-link to="/admin" class="ai-icon" :class="{ 'mm-active': $route.path === '/admin' }" aria-expanded="false">
            <i class="flaticon-381-networking"></i>
            <span class="nav-text">Dashboard</span>
          </router-link>
        </li>

        <li :class="{ 'mm-active': $route.path === '/members' }">
          <router-link to="/members" class="ai-icon" :class="{ 'mm-active': $route.path === '/members' }" aria-expanded="false">
            <i class="fa-solid fa-user"></i>
            <span class="nav-text">Members</span>
          </router-link>
        </li>

        <li :class="{ 'mm-active': $route.path === '/travel' }">
          <router-link to="/travel" class="ai-icon" :class="{ 'mm-active': $route.path === '/travel' }" aria-expanded="false">
            <i class="fa-solid fa-globe"></i>
            <span class="nav-text">Travel Inquiries</span>
          </router-link>
        </li>

        <li :class="{ 'mm-active': $route.path === '/transactions' }">
          <router-link to="/transactions" class="ai-icon" :class="{ 'mm-active': $route.path === '/transactions' }" aria-expanded="false">
            <i class="fa-solid fa-clock-rotate-left"></i>
            <span class="nav-text">Transactions</span>
          </router-link>
        </li>

        <li :class="{ 'mm-active': $route.path === '/newsletter' }">
          <router-link to="/newsletter" class="ai-icon" :class="{ 'mm-active': $route.path === '/newsletter' }" aria-expanded="false">
            <i class="fa-solid fa-newspaper"></i>
            <span class="nav-text">Newsletter</span>
          </router-link>
        </li>
        <li :class="{ 'mm-active': $route.path === '/blog' }">
          <router-link to="/blog" class="ai-icon" :class="{ 'mm-active': $route.path === '/blog' }" aria-expanded="false">
            <i class="fa-regular fa-folder-open"></i>
            <span class="nav-text">Blog</span>
          </router-link>
        </li>

        <!-- <li><a href="javascript:void(0);" class="ai-icon has-arrow" aria-expanded="false">
                        <i class="flaticon-381-id-card-4"></i>
                        <span class="nav-text">Staff</span>
                    </a>
                    <ul aria-expanded="false">
                        <li><a href="staff.html">Staff</a></li>
                        <li><a href="staff-profile.html">Staff Profie</a></li>
                    </ul>
                </li> -->
        <!-- -->
      </ul>

      <!-- <div class="plus-box">
                <p class="fs-16 font-w500 mb-1">Check your job schedule</p>
                <a class="text-white fs-26" href="javascript:;"><i class="las la-long-arrow-alt-right"></i></a>
            </div> -->
      <!-- <div class="copyright">
                <p class="fs-14 font-w200"><strong class="font-w400">Eres Hospital Admin Dashboard</strong> © 2023 All Rights Reserved</p>
                <p class="fs-12">Made with <span class="heart"></span> by DexignZone</p>
            </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "SidebarPage",
  components: {},
  data() {
    return {};
  },

  created() {},

  computed: {},

  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mm-active{
  background: #5164ff;
  color: #fff;
  padding: 0.625rem 2.475rem;
  margin: 5px 0;
  border-radius: 0px 30px 30px 0px;
  -webkit-transition: all 0.5s;
  -ms-transition: all 0.5s;
  transition: all 0.5s;
}
</style>
