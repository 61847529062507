<!-- AdminLayout.vue -->
<template>
    <div>
        <div id="main-wrapper" style="background-color: #f3f8f5;">
            <NavHeader />
            <Header />
            <Sidebar />
            <router-view></router-view>
        </div>
    </div>
</template>
    
<script>
import NavHeader from "../components/AdminLayout/NavHeader.vue";
import Header from "../components/AdminLayout/Header.vue";
import Sidebar from "../components/AdminLayout/Sidebar.vue";

export default {
name: "UserLayout",
components: { Header, NavHeader, Sidebar },
mounted(){
}
};
</script>
    