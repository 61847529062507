// store/auth.js

// import * as dashboard from './user';
import axios from "axios";
import { apis } from "./apis/user";
import router from "../router/routes";

const state = {
  // Additional state if needed
  user: null,
  token: null,
  userTransationHistory: null,
  userProfilePicture: null,
  isLoggedIn: false,
  componentLayout: "",
};

const mutations = {
  SET_LOGIN_DETAILS(state, data) {
    console.log("user set", data);
    state.user = data;
  },
  SET_USER_TRANSACTION_HISTORY(state, data) {
    console.log("userTransationHistory set", data.data);
    state.userTransationHistory = data.data;
  },
  SET_USER_PROFILE_PICTURE(state, data) {
    console.log("SET_USER_PROFILE_PICTURE set", data);
    state.userProfilePicture = data;
  },
  SET_USER_AUTHS(state, data) {
    console.log("SET_USER_AUTHS set", data);
    state.isLoggedIn = data.isLoggedIn;
    state.token = data.token;
  },
  SET_COMPONENT_LAYOUT(state, data) {
    console.log("SET_COMPONENT_LAYOUT set", data);
    state.componentLayout = data.layout;
  },

  // LOGOUT_USER(state, data) {
  //   state.user = data;
  //   state.token = data;
  // },
};

const actions = {
  // Additional actions if needed
  login({ dispatch, commit }, payload) {
    console.log("payload--", payload);

    var formData = new FormData();
    formData.append("email", payload.email);
    formData.append("password", payload.password);

    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.LOGIN, formData)
        .then((res) => {
          console.log("login res--", res);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", JSON.stringify(res.data.result));

          const response = res.data.result;

          // if (response.role_id == "2" || response.role_id === 2) {
          //   let user = {
          //     address: response.address,
          //     avatar: response.avatar,
          //     created_at: response.created_at,
          //     email: response.email,
          //     id: response.id,
          //     name: response.name,
          //     phone: response.phone,
          //     country: response.country,
          //     state: response.state,
          //     zip_code: response.zip_code,
          //     about: response.about,
          //     social_media_links: response.social_media_links,
          //     role_id: response.role_id,
          //     token: res.data.token,
          //   };
          //   commit("SET_LOGIN_DETAILS", user);
          //   commit("SET_USER_AUTHS", {
          //     isLoggedIn: true,
          //     token: res.data.token,
          //   });

          //   router.push("/").then(() => {
          //     router.go();
          //   });
          // }
          router.push("/").then(() => {
            router.go();
          });
          dispatch("checkErrorAndSendToast", ["Succssfully Login", "success"]);

          resolve(res);
        })
        .catch((err) => {
          console.log("login err", err);
          dispatch("checkErrorAndSendToast", [err.response, "error"]);
          reject(err);
        });
    });
  },
  getUserById({ dispatch, commit }, payload) {
    console.log("payload--", payload);
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      return new Promise((resolve, reject) => {
        axios
          .get(axios.defaults.baseURL + apis.GET_USER_BY_ID + user.id, {
            headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
          })
          .then((res) => {
            console.log("getUserById res--", res);
            // return
            localStorage.setItem("user", JSON.stringify(res.data));
            const response = res.data;

            let user = {
              address: response.address,
              avatar: response.avatar,
              updated_at: response.updated_at,
              created_at: response.created_at,
              email: response.email,
              id: response.id,
              name: response.name,
              phone: response.phone,
              country: response.country,
              state: response.state,
              zip_code: response.zip_code,
              about: response.about,
              social_media_links: response.social_media_links,
              role_id: response.role_id,
              token: res.data.token,
            };
            commit("SET_LOGIN_DETAILS", user);
            commit("SET_USER_AUTHS", {
              isLoggedIn: true,
              token: res.data.token,
            });

            resolve(res);
          })
          .catch((err) => {
            console.log("login err", err);
            dispatch("checkErrorAndSendToast", [err.response, "error"]);
            reject(err);
          });
      });
    } else {
      console.log("login err");
    }
  },
  // getProfilePicture({dispatch, commit }) {
  //   const user = JSON.parse(localStorage.getItem("user"));
  //   if(user){
  //     return new Promise((resolve, reject) => {
  //       axios
  //         .get(axios.defaults.baseURL + apis.GET_PROFILE_PICTURE + user.avatar)
  //         .then((res) => {
  //           console.log("getProfilePicture res--", res);
  //           // return

  //             commit("SET_USER_PROFILE_PICTURE", res);
  //           resolve(res)

  //         })
  //         .catch((err) => {
  //           console.log("login err", err);
  //           dispatch("checkErrorAndSendToast", [
  //             err.response,
  //             "error",
  //           ]);
  //           reject(err)
  //         });
  //     })
  //   }
  //   else{
  //     console.log("login err");
  //   }

  // },

  loginWithGoogle({dispatch, commit }, payload) {
    console.log("login loginWithGoogle", payload);
    // return
    var formData = new FormData();
    formData.append("name", payload.name);
    formData.append("email", payload.email);
    formData.append("avatar", payload.photoURL);
    formData.append("role_id", payload.roles);
    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.LOGIN_WITH_GOOGLE, formData)
        .then((res) => {
          console.log("login loginWithGoogle ers", res);
          localStorage.setItem("token", res.data.token);
          localStorage.setItem("user", JSON.stringify(res.data.result));

          const response = res.data.result;

          if (response.role_id == "2" || response.role_id === 2) {
            let user = {
              address: response.address,
              avatar: response.avatar,
              created_at: response.created_at,
              email: response.email,
              id: response.id,
              name: response.name,
              phone: response.phone,
              country: response.country,
              state: response.state,
              zip_code: response.zip_code,
              about: response.about,
              social_media_links: response.social_media_links,
              role_id: response.role_id,
              token: res.data.token,
            };
            commit("SET_LOGIN_DETAILS", user);
            commit("SET_USER_AUTHS", {
              isLoggedIn: true,
              token: res.data.token,
            });

            router.push("/").then(() => {
              router.go();
            });
          }
          dispatch("checkErrorAndSendToast", ["Succssfully Login", "success"]);

          resolve(res);

          commit("UPDATE_LOADING_STATE", false);
        })
        .catch((err) => {
          console.log(err, "login err");
          reject(err);
        });
    });
  },

  userRegister({ dispatch }, payload) {
    console.log("payload--", payload);
    // return

    var formData = new FormData();
    formData.append("name", payload.name);
    formData.append("email", payload.email);
    // formData.append("phone", payload.phone);
    formData.append("password", payload.password);
    formData.append("confirm_password", payload.confirmPassword);
    formData.append("role", "2");
    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.REGISTER_USER, formData)
        .then((res) => {
          console.log("register res", res);
          // localStorage.setItem("token", res.data.data.token);
          router.push("/login-user").then(() => {
            router.go();
          });

          resolve(res);
          dispatch("checkErrorAndSendToast", [
            res.data.message,
            "success",
          ]);
        })
        .catch((err) => {
          console.log("register err", err);
          reject(err);
          dispatch("checkErrorAndSendToast", [err.response, "error"]);
        });
    });
  },

  logout({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          axios.defaults.baseURL + apis.LOGOUT,
          {},
          {
            headers: {
              Authorization: "Bearer " + payload.userToken,
            },
          }
        )
        .then((res) => {
          resolve(res);

          console.log("logout res", res);
          commit("UPDATE_LOADING_STATE", false);

          localStorage.removeItem("token");
          localStorage.removeItem("user");
          router.push("/").then(() => {
            router.go();
          });
          
          dispatch("checkErrorAndSendToast", [
            res.data.message,
            "success",
          ]);
        })
        .catch((err) => {
          reject(err);
          console.log("logout err", err);
          dispatch("checkErrorAndSendToast", [err.response, "error"]);
        });
    });
  },

  forgotPassword({ dispatch }, payload) {
    const formData = {
      email: payload.email
    }
    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.FORGOT_PASSWORD, formData, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          console.log("forgotPassword res", res);

          resolve(res);
          dispatch("checkErrorAndSendToast", [res.data.message, "success"]);
        })
        .catch((err) => {
          console.log("forgotPassword err", err);
          dispatch("checkErrorAndSendToast", [err.response, "error"]);
          reject(err);
        });
    });
  },

  resetPassword({ dispatch }, payload) {
    console.log(payload, "payload");
    const formData = {
      new_password: payload.new_password
    };
    return new Promise((resolve, reject) => {
      axios
        .post(axios.defaults.baseURL + apis.RESET_PASSWORD + payload.token, formData, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .then((res) => {
          console.log("resetPassword res", res);
          router.push("/").then(() => {
            router.go();
          });

          resolve(res);
          dispatch("checkErrorAndSendToast", [res.data.message, "success"]);
        })
        .catch((err) => {
          console.log("resetPassword err", err);
          dispatch("checkErrorAndSendToast", [err.response, "error"]);
          reject(err);
        });
    });
  },
};

const getters = {
  // Additional getters if needed
  auth(state) {
    return state.isLoggedIn;
  },
  Layout(state) {
    return state.componentLayout;
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
