<template>
  <div>
   <!-- <component :is="UserLayout"> -->
     <router-view />
     <Toasts />
   <!-- </component> -->
  </div>
 </template>
 
 <script>
//  import { mapState } from "vuex";
 import Toasts from "@/components/partials/toast.vue";
 
 export default {
   name: 'App',
   components: {
     Toasts,
   },
 
   computed: {
    //  ...mapState({
    //    layout: (state) => state.auth.componentLayout,
    //  }),
    //  currentLayout() {
    //    console.log('this.layout', this.layout);
    //    return this.layout;
    //  },
   },
 }
 </script>
 
 <style>
 </style>
