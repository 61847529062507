<!-- AdminLayout.vue -->
<template>
<div>
    <main>
        <Header />
        <router-view></router-view>
        <Footer />
    </main>
</div>
</template>
  
<script>
import Header from "../components/UserLayout/Header.vue";
import Footer from "../components/UserLayout/Footer.vue";

export default {
name: "UserLayout",
components: { Header, Footer },
mounted(){
}
};
</script>
